import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Status from "../components/Status"
import WrapperView from "../components/WrapperView"

const ThankYou = (props) => {
	const { user } = props;
	return (
		<Layout {...props}>
		<Status auth={props.auth}  user={props.user}  />
		<WrapperView title="">
		<div class="hero">
			<div class="hero-content flex-col lg:flex-row">
				<img src="../thankyou.png" alt="Thank You" className="max-w-xs rounded-lg p-16" />
				<div>
				<h1 class="text-5xl font-bold">Thank you, you made a real deal!🤝</h1>
				<p class="py-6">You have nothing else to do, we will take care of your domains and certificates.</p>
				<Link to="/app/domains"><button className="btn btn-primary">Go to domains</button></Link>
				</div>
			</div>
		</div>
		</WrapperView>
		</Layout>
	)
}

export default ThankYou